import { Injectable, inject } from '@angular/core';
import { NgKLoggerService } from '@kin/ngk-logger';

import { isDateYYYYMMDD } from 'app/common/utils/date-helper/date-helper';
import { OtherPolicyFeaturesMapperService } from 'app/create-quote/quote-review/additional-coverages/map-other-policy-features';
import { extractCoverageAmountAndPercentage } from 'app/create-quote/quote-review/additional-coverages/other-features.utils';
import { FloodProtectionMapperService } from 'app/create-quote/quote-review/flood-protection/map-flood-protection';
import { PrimaryCoverageMapperService } from 'app/create-quote/quote-review/primary-coverages/map-primary-coverages';
import { RspsMapperService } from 'app/create-quote/quote-review/rsps/map-rsps';

import { createPolicy } from './policies.factory';
import { Endorsement, Policy } from './policies.model';
import {
  LegacyApiApplicant,
  LegacyApiAutomaticallyAppliedFunnelDiscount,
  LegacyApiCoverage,
  LegacyApiFee,
  LegacyApiGetPolicyResponse,
  LegacyApiLimitedFungiMetadata,
  LegacyApiPatchPolicyResponse,
  LegacyApiPolicyMetadata,
} from './policies.service';

/**
 * Custom error class for unexpected API responses
 */
export class UnexpectedResponseError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'UnexpectedResponseError';
  }
}

@Injectable({
  providedIn: 'root',
})
export class LegacyPolicyResponseMapper {
  logger = inject(NgKLoggerService);
  primaryCoverageMapper = inject(PrimaryCoverageMapperService);
  floodProtectionMapper = inject(FloodProtectionMapperService);
  otherPolicyFeaturesMapper = inject(OtherPolicyFeaturesMapperService);
  rspsMapper = inject(RspsMapperService);

  /**
   * Maps API response to Policy model, throws UnexpectedResponseError if there are primary coverage mismatches
   */
  fromGetResponse(response: LegacyApiGetPolicyResponse, throwOnMismatch = false): Policy {
    const parsedPolicy: Policy = createPolicy();

    // Use the validatePrimaryCoverages flag to check for mismatches
    const mappedCoverages = this.primaryCoverageMapper.mapPrimaryCoverages(response.coverages.main_coverages, throwOnMismatch);
    const mappedFloodProtection = this.floodProtectionMapper.mapFloodProtection(response.add_ons);

    const mappedOtherCoverages = this.otherPolicyFeaturesMapper.mapOtherPolicyFeatures(response.coverages.other_coverages);
    const rspsCoverage = response.coverages.main_coverages.find((coverage) => coverage.component.attribute_name === 'roof_surface_payment_schedule');
    let mappedRsps: Endorsement[] = [];
    if (rspsCoverage) {
      const rspsResult = this.rspsMapper.map(rspsCoverage);
      mappedRsps = rspsResult ? [rspsResult] : [];
    }
    const limitedFungiCoverage = response.coverages.other_coverages?.find((coverage) => coverage.component.attribute_name === 'limited_fungi');

    return {
      ...parsedPolicy,
      ...this.mapMetadata(response.metadata),
      ...this.mapRedirectTo(response.redirect_to),
      ...this.mapEffectiveDate(response.effective_date),
      ...this.mapDiscounts(response.automatically_applied_funnel_discounts),
      ...this.mapLimitedFungiMetadata(response.limited_fungi_metadata, limitedFungiCoverage),
      ...this.mapDeductibles(response.coverages.deductibles),
      ...this.mapAndValidateFees(response.fees),
      ...this.mapWindMitMetadata(response),
      primaryCoverages: mappedCoverages,
      additionalCoverages: mappedOtherCoverages,
      endorsements: [...mappedFloodProtection, ...mappedRsps],
    };
  }

  /**
   * Maps patch API response to partial Policy model, throws UnexpectedResponseError if there are primary coverage mismatches
   */
  fromPatchResponse(response: LegacyApiPatchPolicyResponse, throwOnMismatch = false): Partial<Policy> {
    const policyUpdate: Partial<Policy> = {};

    const rspsCoverage = response.coverages.main_coverages.find((coverage) => coverage.component.attribute_name === 'roof_surface_payment_schedule');
    let mappedRsps: Endorsement[] = [];
    if (rspsCoverage) {
      const rspsResult = this.rspsMapper.map(rspsCoverage);
      mappedRsps = rspsResult ? [rspsResult] : [];
    }
    const limitedFungiCoverage = response.coverages.other_coverages?.find((coverage) => coverage.component.attribute_name === 'limited_fungi');

    return {
      ...policyUpdate,
      ...this.mapMetadata(response.metadata),
      ...this.mapEffectiveDate(response.effective_date),
      ...this.mapDeductibles(response.coverages.deductibles),
      ...this.mapRedirectTo(response.redirect_to),
      ...this.mapDiscounts(response.automatically_applied_funnel_discounts),
      ...this.mapLimitedFungiMetadata(response.limited_fungi_metadata, limitedFungiCoverage),
      ...this.mapWindMitMetadata(response),
      ...this.mapAndValidateFees(response.fees),
      ...(response.coverages?.main_coverages && { primaryCoverages: this.primaryCoverageMapper.mapPrimaryCoverages(response.coverages.main_coverages, throwOnMismatch) }),
      ...(response.coverages?.other_coverages && { additionalCoverages: this.otherPolicyFeaturesMapper.mapOtherPolicyFeatures(response.coverages.other_coverages) }),
      ...(response.add_ons && { endorsements: [...this.floodProtectionMapper.mapFloodProtection(response.add_ons), ...mappedRsps] }),
    };
  }

  private mapMetadata(metadata: LegacyApiPolicyMetadata | null): Partial<Policy> {
    if (!metadata) {
      this.logWarning('Metadata is missing from the response.');
      return {};
    }

    const primaryApplicant = metadata.applicants.find((applicant: LegacyApiApplicant) => !applicant.co_applicant);

    const parsedMetadata: Partial<Policy> = {
      address: metadata.address,
      firstName: primaryApplicant?.first_name,
      fullName: primaryApplicant?.name,
      policyType: metadata.policy_type?.label,
      premium: metadata.premium,
    };

    this.validateMetadata(parsedMetadata);
    return parsedMetadata;
  }

  private validateMetadata(parsedMetadata: Partial<Policy>): void {
    const requiredFields: (keyof Partial<Policy>)[] = ['address', 'firstName', 'fullName', 'policyType', 'premium'];
    requiredFields.forEach((field) => {
      if (!parsedMetadata[field]) {
        this.logWarning(`${field} is missing in metadata.`);
      }
    });
  }

  private mapRedirectTo(redirectTo: string | undefined): Partial<Policy> {
    if (!redirectTo) {
      this.logWarning('Redirect to is missing from the response.');
      return {};
    }
    return { redirectTo };
  }

  private mapEffectiveDate(effectiveDate: LegacyApiCoverage | undefined): Partial<Policy> {
    if (!effectiveDate?.value) {
      this.logWarning('Effective date is missing or invalid in the response.');
      return {};
    }

    // validate string is in YYYY-MM-DD format
    if (!isDateYYYYMMDD(effectiveDate.value.toString())) {
      this.logWarning('Effective date is not in YYYY-MM-DD format.');
      return {};
    }
    return { effectiveDate: effectiveDate.value.toString() };
  }

  private mapDiscounts(discounts: LegacyApiAutomaticallyAppliedFunnelDiscount | undefined): Partial<Policy> {
    if (!discounts) {
      this.logWarning('Discounts are missing from the response.');
      return {};
    }
    return {
      discounts: Object.entries(discounts).map(([label, value]) => ({ label, value: Number(value) })),
    };
  }

  private mapLimitedFungiMetadata(limitedFungiMetadata: LegacyApiLimitedFungiMetadata | undefined, limitedFungiCoverage: LegacyApiCoverage | undefined): Partial<Policy> {
    if (!limitedFungiMetadata) {
      if (limitedFungiCoverage) {
        this.logWarning('Expected Limited Fungi Metadata is missing from the response.');
      }
      return {};
    }

    return {
      limitedFungiMetadata: {
        firstCoverageLimit: limitedFungiMetadata.first_coverage_limit,
        secondCoverageLimit: limitedFungiMetadata.second_coverage_limit,
      },
    };
  }

  private mapWindMitMetadata(response: LegacyApiGetPolicyResponse | LegacyApiPatchPolicyResponse): Partial<Policy> | undefined {
    if (response.windstorm_mitigation_discount_metadata) {
      if (response.windstorm_mitigation_discount_metadata.active) {
        const windMitDiscount = response.automatically_applied_funnel_discounts?.['Wind mitigation discount'];
        if (!windMitDiscount) {
          this.logWarning('Expected Wind Mit Discount is missing from the response, but Wind Mit is active');
        }
      }

      if (typeof response.windstorm_mitigation_discount_metadata.active !== 'boolean') {
        this.logWarning(`Expected Wind Mit Metadata active property is missing or not a boolean: ${response.windstorm_mitigation_discount_metadata.active}`);
      }

      if (typeof response.windstorm_mitigation_discount_metadata.editable !== 'boolean') {
        this.logWarning(`Expected Wind Mit Metadata editable property is missing or not a boolean: ${response.windstorm_mitigation_discount_metadata.editable}`);
      }

      return {
        windMitMetadata: {
          active: response.windstorm_mitigation_discount_metadata.active,
          editable: response.windstorm_mitigation_discount_metadata.editable,
        },
      };
    }
    return undefined;
  }

  private mapDeductibles(deductibles: LegacyApiCoverage[] | undefined): Partial<Policy> {
    if (!deductibles) {
      this.logWarning('Deductibles are missing from the response.');
      return {};
    }
    const output = {
      deductibles: deductibles.map((deductible: LegacyApiCoverage) => ({
        id: deductible.component.attribute_name,
        value: this.validateDeductibleValue(deductible),
        options: this.mapAndValidateDeductibleOptions(deductible),
      })),
    };
    return output;
  }

  private validateDeductibleValue(deductible: LegacyApiCoverage): string | undefined {
    if (deductible.value === undefined || deductible.value === null) {
      this.logWarning(`Deductible value is missing or undefined for ${deductible.component.attribute_name}`);
      return undefined;
    }

    if (typeof deductible.value === 'boolean') {
      this.logWarning(`Deductible value is boolean for ${deductible.component.attribute_name}`);
      return undefined;
    }

    if (typeof deductible.value !== 'number' && typeof deductible.value !== 'string') {
      this.logWarning(`Deductible value is not a number or string for ${deductible.component.attribute_name}`);
      return undefined;
    }

    return deductible.value.toString();
  }

  private mapAndValidateDeductibleOptions(deductible: LegacyApiCoverage): { id: string; label: string; value: string }[] {
    if (!deductible.component.select_options) {
      return [];
    }

    return deductible.component.select_options.map((option, index) => {
      const { percentage, value } = extractCoverageAmountAndPercentage(option.label);
      let formattedLabel = option.label;

      if (percentage && value) {
        formattedLabel = `${value} (${percentage} of Coverage A)`;
      } else if (percentage) {
        formattedLabel = `${percentage} of Coverage A`;
      } else if (value) {
        formattedLabel = value;
      }

      return {
        id: `${deductible.component.attribute_name}-_option_${index + 1}`,
        label: formattedLabel,
        value: option.value.toString(),
      };
    });
  }

  private mapAndValidateFees(fees: LegacyApiFee[] | undefined): Partial<Policy> {
    if (!fees) {
      this.logWarning('Fees array is missing from the response');
      return { fees: [] };
    }

    return {
      fees: fees
        .filter((fee) => {
          if (!fee.id) {
            this.logWarning('Fee is missing required field: id');
            return false;
          }
          if (!fee.label) {
            this.logWarning('Fee is missing required field: label');
            return false;
          }
          if (!fee.formatted_value) {
            this.logWarning('Fee is missing required field: formatted_value');
            return false;
          }

          // Check for valid value
          if (fee.value === null || fee.value === undefined) {
            this.logWarning(`Fee value is missing or invalid for ${fee.id}`);
            return false;
          }
          if (typeof fee.value !== 'number') {
            this.logWarning(`Fee value must be a number for ${fee.id}`);
            return false;
          }
          if (fee.value < 0) {
            this.logWarning(`Fee value cannot be negative for ${fee.id}`);
            return false;
          }

          return true;
        })
        .map((fee) => ({
          id: fee.id,
          value: fee.value,
          label: fee.label,
          formattedValue: fee.formatted_value,
        })),
    };
  }

  private logWarning(message: string): void {
    this.logger.error({
      priority: 'P3',
      context: `LegacyPolicyResponseMapperService`,
      message: `Unexpected response from Policies controller: "${message}"`,
    });
  }
}
