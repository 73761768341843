{
  "name": "quoting",
  "version": "0.0.0-c236ac3.0",
  "private": true,
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 4200",
    "start:dev": "ng serve --configuration development",
    "build": "ng build",
    "analyze-build": "ng build --stats-json && source-map-explorer dist/quoting/browser/**/*.js --no-border-checks",
    "test": "ng test",
    "test:watch": "ng test --watch --code-coverage",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "ci:test": "ng test --no-progress --browsers=ChromeHeadlessCI --code-coverage",
    "ci:e2e": "start-server-and-test start http://localhost:4200 cypress:run",
    "ci:e2e:critical": "start-server-and-test start http://localhost:4200 --timeout 300 'npx cypress-split --ci-build-id $GITHUB_RUN_ID --group critical --spec cypress/e2e/critical-path/*.cy.ts'",
    "ci:e2e:forms": "start-server-and-test start http://localhost:4200 --timeout 300 'npx cypress-split --ci-build-id $GITHUB_RUN_ID --group forms --spec cypress/e2e/feature-tests/quote-form-tests/**/*.cy.ts'",
    "ci:audit": "npm audit --omit=dev --audit-level=high",
    "ci:build": "ng build",
    "ci:lint": "ng lint",
    "e2e": "ng e2e",
    "cypress:open": "npx cypress open",
    "cypress:run": "npx cypress run",
    "prepare": "husky",
    "precommit": "lint-staged",
    "dd-app-version": "npm --no-git-tag-version version pre --preid=$(git rev-parse --short HEAD)",
    "dd-upload-sourcemaps": "datadog-ci sourcemaps upload dist/quoting/browser --service=quoting-ui --release-version=$VERSION --minified-path-prefix=/ --repository-url=https://github.com/kin/quoting-ui"
  },
  "dependencies": {
    "@angular-architects/ngrx-toolkit": "19.0.2",
    "@angular/animations": "^19.2.4",
    "@angular/common": "^19.2.4",
    "@angular/compiler": "^19.2.4",
    "@angular/core": "^19.2.4",
    "@angular/forms": "^19.2.4",
    "@angular/platform-browser": "^19.2.4",
    "@angular/platform-browser-dynamic": "^19.2.4",
    "@angular/router": "^19.2.4",
    "@datadog/browser-rum": "^5.31.1",
    "@fullstory/browser": "~1.4.6",
    "@kin/css": "^3.10.0",
    "@kin/ngk-logger": "^2.0.1",
    "@kin/web-components": "^5.11.0",
    "@maskito/angular": "^3.0.1",
    "@maskito/core": "^3.4.0",
    "@maskito/kit": "^3.0.1",
    "@ng-icons/core": "^31.2.0",
    "@ng-icons/lucide": "^31.2.0",
    "@ng-web-apis/common": "^4.11.1",
    "@ngrx/operators": "19.0.1",
    "@ngrx/signals": "19.0.1",
    "@splitsoftware/splitio": "^10.25.2",
    "@tailwindcss/container-queries": "^0.1.1",
    "cally": "^0.8.0",
    "date-fns": "^4.1.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.5",
    "@angular-eslint/builder": "^19.2.1",
    "@angular-eslint/eslint-plugin": "^19.2.1",
    "@angular-eslint/eslint-plugin-template": "^19.2.1",
    "@angular-eslint/schematics": "^19.2.1",
    "@angular-eslint/template-parser": "^19.2.1",
    "@angular/cli": "^19.2.5",
    "@angular/compiler-cli": "^19.2.4",
    "@babel/preset-typescript": "^7.26.0",
    "@cypress/schematic": "^2.5.2",
    "@datadog/datadog-ci": "^2.45.1",
    "@faker-js/faker": "^9.0.2",
    "@types/jasmine": "~5.1.0",
    "@types/lodash": "^4.17.6",
    "@types/supertest": "^6.0.2",
    "@typescript-eslint/eslint-plugin": "^8.26.1",
    "@typescript-eslint/parser": "^8.26.1",
    "cypress": "^13.13.0",
    "cypress-mochawesome-reporter": "^3.8.2",
    "cypress-multi-reporters": "^1.6.4",
    "cypress-split": "^1.24.7",
    "eslint": "^8.57.0",
    "eslint-config-prettier": "^9.1.0",
    "eslint-import-resolver-typescript": "^3.8.7",
    "eslint-plugin-import": "^2.31.0",
    "eslint-plugin-prettier": "^5.1.3",
    "eslint-plugin-unused-imports": "^4.1.4",
    "husky": "^9.1.6",
    "jasmine-core": "~5.1.0",
    "jest": "^29.7.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-htmlfile-reporter": "^0.3.8",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lint-staged": "^15.5.0",
    "mocha-junit-reporter": "^2.2.1",
    "postcss-import": "^16.1.0",
    "prettier": "^3.3.3",
    "prettier-plugin-tailwindcss": "^0.6.8",
    "source-map-explorer": "^2.5.3",
    "start-server-and-test": "^2.0.4",
    "supertest": "^7.0.0",
    "tailwindcss": "^3.4.10",
    "ts-jest": "^29.2.5",
    "typescript": "~5.8.2"
  },
  "engines": {
    "node": "^20.0.0",
    "npm": "^10.0.0"
  }
}
