import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'app-social-proof',
  standalone: true,
  imports: [],
  template: `
    <div class="card flex flex-col justify-between md:w-full md:gap-300">
      <div class="flex-column flex max-w-full items-center justify-between gap-200 md:items-start">
        <img src="assets/images/google.svg" class="h-[35px] w-[98px] md:h-[45px] md:w-[125px]" alt="Google Logo" />
        <div class="flex-column flex w-full items-center gap-200 md:flex-row">
          <h3 class="text-2xl font-semibold text-green-600">4.6/5</h3>
          <b class="neutral-600 text-sm font-normal">Based on 5,600+ reviews</b>
        </div>
      </div>
      <div class="hidden bg-gray-300 md:flex md:h-[1px]"></div>
      <div id="five-stars" class="hidden flex-row md:flex">
        <svg width="24" height="24" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.04894 0.927052C9.3483 0.00574112 10.6517 0.00573993 10.9511 0.927051L12.4697 5.60081C12.6035
                    6.01284 12.9875 6.2918 13.4207 6.2918H18.335C19.3037 6.2918 19.7065 7.53141 18.9228 8.10081L14.947
                    10.9894C14.5966 11.244 14.4499 11.6954 14.5838 12.1074L16.1024 16.7812C16.4017 17.7025 15.3472 
                    18.4686 14.5635 17.8992L10.5878 15.0106C10.2373 14.756 9.7627 14.756 9.41221 15.0106L5.43648 
                    17.8992C4.65276 18.4686 3.59828 17.7025 3.89763 16.7812L5.41623 12.1074C5.55011 11.6954 5.40345 
                    11.244 5.05296 10.9894L1.07722 8.10081C0.293507 7.53141 0.696283 6.2918 1.66501 6.2918H6.57929C7.01252 
                    6.2918 7.39647 6.01284 7.53035 5.60081L9.04894 0.927052Z"
            fill="#277540"
          />
        </svg>
        <svg width="24" height="24" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.04894 0.927052C9.3483 0.00574112 10.6517 0.00573993 10.9511 0.927051L12.4697 5.60081C12.6035
                    6.01284 12.9875 6.2918 13.4207 6.2918H18.335C19.3037 6.2918 19.7065 7.53141 18.9228 8.10081L14.947
                    10.9894C14.5966 11.244 14.4499 11.6954 14.5838 12.1074L16.1024 16.7812C16.4017 17.7025 15.3472 
                    18.4686 14.5635 17.8992L10.5878 15.0106C10.2373 14.756 9.7627 14.756 9.41221 15.0106L5.43648 
                    17.8992C4.65276 18.4686 3.59828 17.7025 3.89763 16.7812L5.41623 12.1074C5.55011 11.6954 5.40345 
                    11.244 5.05296 10.9894L1.07722 8.10081C0.293507 7.53141 0.696283 6.2918 1.66501 6.2918H6.57929C7.01252 
                    6.2918 7.39647 6.01284 7.53035 5.60081L9.04894 0.927052Z"
            fill="#277540"
          />
        </svg>
        <svg width="24" height="24" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.04894 0.927052C9.3483 0.00574112 10.6517 0.00573993 10.9511 0.927051L12.4697 5.60081C12.6035
                    6.01284 12.9875 6.2918 13.4207 6.2918H18.335C19.3037 6.2918 19.7065 7.53141 18.9228 8.10081L14.947
                    10.9894C14.5966 11.244 14.4499 11.6954 14.5838 12.1074L16.1024 16.7812C16.4017 17.7025 15.3472 
                    18.4686 14.5635 17.8992L10.5878 15.0106C10.2373 14.756 9.7627 14.756 9.41221 15.0106L5.43648 
                    17.8992C4.65276 18.4686 3.59828 17.7025 3.89763 16.7812L5.41623 12.1074C5.55011 11.6954 5.40345 
                    11.244 5.05296 10.9894L1.07722 8.10081C0.293507 7.53141 0.696283 6.2918 1.66501 6.2918H6.57929C7.01252 
                    6.2918 7.39647 6.01284 7.53035 5.60081L9.04894 0.927052Z"
            fill="#277540"
          />
        </svg>
        <svg width="24" height="24" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.04894 0.927052C9.3483 0.00574112 10.6517 0.00573993 10.9511 0.927051L12.4697 5.60081C12.6035
                    6.01284 12.9875 6.2918 13.4207 6.2918H18.335C19.3037 6.2918 19.7065 7.53141 18.9228 8.10081L14.947
                    10.9894C14.5966 11.244 14.4499 11.6954 14.5838 12.1074L16.1024 16.7812C16.4017 17.7025 15.3472 
                    18.4686 14.5635 17.8992L10.5878 15.0106C10.2373 14.756 9.7627 14.756 9.41221 15.0106L5.43648 
                    17.8992C4.65276 18.4686 3.59828 17.7025 3.89763 16.7812L5.41623 12.1074C5.55011 11.6954 5.40345 
                    11.244 5.05296 10.9894L1.07722 8.10081C0.293507 7.53141 0.696283 6.2918 1.66501 6.2918H6.57929C7.01252 
                    6.2918 7.39647 6.01284 7.53035 5.60081L9.04894 0.927052Z"
            fill="#277540"
          />
        </svg>
        <svg width="24" height="24" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9.04894 0.927052C9.3483 0.00574112 10.6517 0.00573993 10.9511 0.927051L12.4697 5.60081C12.6035
                    6.01284 12.9875 6.2918 13.4207 6.2918H18.335C19.3037 6.2918 19.7065 7.53141 18.9228 8.10081L14.947
                    10.9894C14.5966 11.244 14.4499 11.6954 14.5838 12.1074L16.1024 16.7812C16.4017 17.7025 15.3472 
                    18.4686 14.5635 17.8992L10.5878 15.0106C10.2373 14.756 9.7627 14.756 9.41221 15.0106L5.43648 
                    17.8992C4.65276 18.4686 3.59828 17.7025 3.89763 16.7812L5.41623 12.1074C5.55011 11.6954 5.40345 
                    11.244 5.05296 10.9894L1.07722 8.10081C0.293507 7.53141 0.696283 6.2918 1.66501 6.2918H6.57929C7.01252 
                    6.2918 7.39647 6.01284 7.53035 5.60081L9.04894 0.927052Z"
            fill="#DBDBDB"
          />
          <mask id="mask0_13238_28319" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
            <path
              d="M9.04894 0.927052C9.3483 0.00574112 10.6517 0.00573993 10.9511 0.927051L12.4697 5.60081C12.6035
                    6.01284 12.9875 6.2918 13.4207 6.2918H18.335C19.3037 6.2918 19.7065 7.53141 18.9228 8.10081L14.947
                    10.9894C14.5966 11.244 14.4499 11.6954 14.5838 12.1074L16.1024 16.7812C16.4017 17.7025 15.3472 
                    18.4686 14.5635 17.8992L10.5878 15.0106C10.2373 14.756 9.7627 14.756 9.41221 15.0106L5.43648 
                    17.8992C4.65276 18.4686 3.59828 17.7025 3.89763 16.7812L5.41623 12.1074C5.55011 11.6954 5.40345 
                    11.244 5.05296 10.9894L1.07722 8.10081C0.293507 7.53141 0.696283 6.2918 1.66501 6.2918H6.57929C7.01252 
                    6.2918 7.39647 6.01284 7.53035 5.60081L9.04894 0.927052Z"
              fill="#C8C7C6"
            />
          </mask>
          <g mask="url(#mask0_13238_28319)">
            <rect x="-7.5" y="-4" width="18" height="31" fill="#277540" />
          </g>
        </svg>
      </div>
      <b class="hidden text-base font-normal md:inline"
        >Remarkable website. Fast, clear, and inclusive. <span class="font-bold">Insurance premiums are most competitive</span>. Very impressed so far.</b
      >
      <div class="hidden flex-row gap-300 text-base font-normal md:flex">
        <p>Richard S.</p>
        <a
          href="https://maps.app.goo.gl/aW3AEmeaM9fu4byKA"
          target="_blank"
          rel="noopener"
          class="gap-300 text-sm text-green-600 underline underline-offset-2 outline-none focus:ring-2 focus:ring-primary"
          >Google</a
        >
      </div>
      <div class="hidden bg-gray-300 md:inline md:h-[1px]"></div>
      <p class="custom-tiny-font hidden md:flex">
        The opinions expressed belong solely to individual reviewers and do not reflect the opinions of the Kin Insurance, Inc. group of companies or affiliates. Reviews may be from customers in any
        state where Kin operates and are for informational purposes only.
      </p>
    </div>
  `,
  styles: `
    .custom-tiny-font {
      font-size: 0.625rem;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocialProofComponent {}
