import { Injectable, inject } from '@angular/core';
import { NgKLoggerService } from '@kin/ngk-logger';

import { UnexpectedResponseError } from 'app/common/services/legacy/policies/legacy-policy-response-mapper.service';
import { PrimaryCoverage, PrimaryCoverageOption, PrimaryCoverageOptionValue } from 'app/common/services/legacy/policies/policies.model';
import { LegacyApiCoverage } from 'app/common/services/legacy/policies/policies.service';

import { isPercentageCoverage } from '../additional-coverages/other-features.utils';

@Injectable({
  providedIn: 'root',
})
export class PrimaryCoverageMapperService {
  private logger = inject(NgKLoggerService);

  mapPrimaryCoverages(primaryCoverages: LegacyApiCoverage[] | undefined, throwOnMismatch = false): PrimaryCoverage[] {
    if (!primaryCoverages) {
      return [];
    }
    return (
      primaryCoverages
        // Filter RSPS because we handle it separately
        .filter((item) => item.component?.attribute_name !== 'roof_surface_payment_schedule')
        .map((item) => {
          const component = item.component || {};
          const selectOptions = component.select_options || [];

          if (item.editable !== undefined && item.editable && this.parseAndValidateOptions(selectOptions).length === 0) {
            this.logger.event({
              metaData: {
                context: 'PrimaryCoverageMapper::mapPrimaryCoverages',
                message: 'Editable primary coverage with no valid options returned',
                data: {
                  response: primaryCoverages,
                  coverage: item,
                },
              },
              name: 'unexpected-api-response',
            });
          }

          const output = {
            id: this.parseAndValidateId(component.attribute_name),
            isEditable: item.editable !== undefined ? item.editable : false,
            value: this.parseAndValidateValue(item.value, item),
            options: this.parseAndValidateOptions(selectOptions),
          };

          if (output.value !== undefined && output.options.length > 0 && !output.options.some((option) => String(option.value) === String(output.value))) {
            this.logger.event({
              metaData: {
                response: primaryCoverages,
                context: 'PrimaryCoverageMapper::mapPrimaryCoverages',
                message: 'Primary coverage value is not a valid option',
                data: output,
              },
              name: 'unexpected-api-response',
            });

            // Throw error when throwOnMismatch is true
            if (throwOnMismatch) {
              throw new UnexpectedResponseError(`Primary coverage value (${output.value}) is not in options list for ${output.id}`);
            }
          }

          return output;
        })
    );
  }

  parseAndValidateId(value: unknown): string | undefined {
    let result: string | undefined;

    if (typeof value === 'string') {
      const trimmed = value.trim();
      result = trimmed.length > 0 ? trimmed : undefined;
    } else if (typeof value === 'number') {
      result = String(value);
    }

    if (result === undefined && value !== undefined) {
      this.logger.error({
        message: `Primary Coverage ID: Failed to parse valid ID: ${value}. Coverage may not appear in the UI.`,
        context: 'PrimaryCoverageMapper::parseAndValidateId',
        priority: 'P3',
      });
    }

    return result;
  }

  parseAndValidateValue(value: unknown, coverage?: LegacyApiCoverage): PrimaryCoverageOptionValue | undefined {
    if (value === null || value === undefined) {
      return undefined;
    }

    /**
     * For non-editable percentage values, return the formatted content
     * For example, Coverage D has a value of "20%" and is not editable,
     * so the UI needs to display the actual value "(20%) $85,600"
     */

    if (coverage?.editable === false && isPercentageCoverage(coverage.value)) {
      if (typeof coverage.component?.content === 'string') {
        return coverage.component.content;
      }

      this.logger.event({
        metaData: {
          context: 'PrimaryCoverageMapper::parseAndValidateValue',
          message: "API returned a percentage coverage but no content so we can't display the actual value in the UI",
          data: coverage,
        },
        name: 'unexpected-api-response',
      });
    }

    if (typeof value === 'string' || typeof value === 'number') {
      return value;
    }

    return undefined;
  }

  parseAndValidateEditable(value: unknown): boolean {
    if (typeof value === 'boolean') {
      return value;
    }

    if (typeof value === 'string') {
      const trimmed = value.trim().toLowerCase();
      if (trimmed === 'true') return true;
      if (trimmed === 'false') return false;
    }

    // Log only when we're adding the fallback false
    this.logger.error({
      message: `Primary Coverage editable: Using default false value: ${value}`,
      context: 'PrimaryCoverageMapper::parseAndValidateEditable',
      priority: 'P3',
    });
    return false;
  }

  parseAndValidateOptions(options: unknown): PrimaryCoverageOption[] {
    if (!options) {
      return [];
    }
    if (!Array.isArray(options)) {
      this.logger.event({
        metaData: {
          context: 'PrimaryCoverageMapper::parseAndValidateOptions',
          message: 'Primary Coverage options: Received non-array, expected array',
          data: options,
        },
        name: 'unexpected-api-response',
      });
      return [];
    }

    const validOptions = options
      .map((option, index) => {
        const label = this.parseAndValidateOptionLabel(option.label);
        const value = this.parseAndValidateOptionValue(option.value);
        if (!label || value === undefined) {
          this.logger.error({
            message: `Invalid option at index ${index}: label=${option.label}, value=${option.value}`,
            context: 'PrimaryCoverageMapper::parseAndValidateOptions',
            priority: 'P3',
          });
          return null;
        }
        return { label, value };
      })
      .filter((option): option is PrimaryCoverageOption => option !== null);

    if (validOptions.length === 0 && options.length > 0) {
      this.logger.error({
        message: 'All options were invalid, resulting in an empty array',
        context: 'PrimaryCoverageMapper::parseAndValidateOptions',
        priority: 'P3',
      });
    }

    return validOptions;
  }

  parseAndValidateOptionLabel(label: unknown): string | undefined {
    if (typeof label === 'number') {
      return String(label);
    }

    if (typeof label !== 'string') {
      this.logger.error({ message: `Primary Coverage option label: Non-string received: ${label}`, context: 'PrimaryCoverageMapper::parseAndValidateOptionLabel', priority: 'P3' });
      return undefined;
    }

    const trimmed = label.trim();
    if (trimmed.length === 0) {
      return undefined;
    }

    return trimmed;
  }

  parseAndValidateOptionValue(value: unknown): string | number | undefined {
    if (typeof value === 'string' || typeof value === 'number') {
      return value;
    }
    this.logger.error({ message: `Primary Coverage option value: Invalid type received: ${value}`, context: 'PrimaryCoverageMapper::parseAndValidateOptionValue', priority: 'P3' });
    return undefined;
  }
}
